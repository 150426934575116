import { format } from 'date-fns';

export const LOGIN_USER = "LOGIN USER";

export const BANDEIRAS = [
  "Carrefour (Hiper & Super)",
  // "Express",
  "Atacadão",
  "Sams Club",
];
export const TIPOS_ATIVOS = ["POS Material", "DOOH", "Onsite", "Offsite"];
export const REGIOES = [
  {
    id: "SP",
    regiao: "São Paulo",
  },
  {
    id: "CO",
    regiao: "Centro-Oeste",
  },
  {
    id: "NO",
    regiao: "Norte",
  },
  {
    id: "NE",
    regiao: "Nordeste",
  },
  {
    id: "SE",
    regiao: "Sudeste",
  },
  {
    id: "SUL",
    regiao: "Sul",
  },
];

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDXvPqrmbesMwONIBY_HuNRgS27oOHpxyg",
  authDomain: "development-431723.firebaseapp.com",
  projectId: "development-431723",
  storageBucket: "development-431723.appspot.com",
  messagingSenderId: "402722679501",
  appId: "1:402722679501:web:c5a4fd57a7920aefd25784",
  measurementId: "G-F3XSZP7SCW",
};

export function getColor(name) {
  switch (name) {
    case "ILSP":
      return "#FFCDD2";
    case "SP":
      return "#F0F4C3";
    case "NO":
      return "#BBDEFB";
    case "NE":
      return "#FFE0B2";
    case "CO":
      return "#D1C4E9";
    case "SE":
      return "#C8E6C9";
    case "SUL":
      return "#FFECB3";

    case "Carrefour":
      return "#6a9dc6";

    case "Atacadão":
      return "#ffa500";

    case "Sams Club":
      return "#add8e6";

    default:
      break;
  }
}

//getEstadosPorRegiao('NE')
export function getEstadosPorRegiao(regiao) {
  const regioes = {
    SP: ["SP"], // Somente estado de SP
    NO: ["AC", "AP", "AM", "PA", "RO", "RR", "TO"], // Norte
    NE: ["AL", "BA", "CE", "MA", "PB", "PE", "PI", "RN", "SE"], // Nordeste
    SE: ["ES", "MG", "RJ", "SP"], // Sudeste
    SUL: ["PR", "RS", "SC"], // Sul
    CO: ["DF", "GO", "MT", "MS"], // Centro-Oeste
  };

  return regioes[regiao] || [];
}

export function formatCurrency(value) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

//valueFormatter: (params) => formatDate(params.value),
export function formatDate(value, _format = 'dd/MM/yyyy') {
  if (!value) return '';
  return format(new Date(value), _format);
}

//renderCell: (params) => formatBandeira(params.value),
export function formatBandeira(retailer) {
  switch (retailer.toUpperCase()) {
    case "CARREFOUR (HIPER & SUPER)":
      return (
        <div
          style={{
            color: "#bf360c",
            fontWeight: "bold",
          }}
        >
          CRF
        </div>
      );
    case "EXPRESS":
      return (
        <div
          style={{
            color: "#f39c12",
            fontWeight: "bold",
          }}
        >
          EXP
        </div>
      );
    case "SAMS CLUB":
      return (
        <div
          style={{
            color: "#5dade2",
            fontWeight: "bold",
          }}
        >
          SAMS
        </div>
      );
    case "ATACADÃO":
      return (
        <div
          style={{
            color: "#2ecc71",
            fontWeight: "bold",
          }}
        >
          ATC
        </div>
      );
    default:
      return "";
  }
}

//Permissão Total
export function hasFullPermission(user) {
  const users = ["luiz.flores@unlimitail.com", "jonatas.alves@unlimitail.com", "louis.balaire@unlimitail.com", "jessica.gelman@unlimitail.com",
    "cyril.marchal@unlimitail.com", "vanessa.cecco@unlimitail.com", "danilo.alvarenga@unlimitail.com", "vinicius.calil@unlimitail.com",
    "marina.zeferino@unlimitail.com", "ricardo.sfeir@unlimitail.com", "matheus.franco@unlimitail.com", "otavio.naveira@unlimitail.com", "rodrigo.pimentel@unlimitail.com"];
  return users.includes(user)
}
