// src/authConfig.js
export const msalConfig = {
  auth: {
    clientId: "044ff75b-3f0a-4d75-aec4-bec2ffc171b1", // Substitua pelo seu client ID
    authority:
      "https://login.microsoftonline.com/85a52eff-3d71-4d72-b7ba-e9f19c3eb832", // Ou o seu tenant específico
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage", // Pode ser "sessionStorage" se preferir
    storeAuthStateInCookie: false, // Defina como true para suporte ao IE 11
  },
};

export const loginRequest = {
  scopes: ["openid", "profile", "email", "User.Read"],
};
