import React, { useRef } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import FaceIcon from "@mui/icons-material/Face";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { LOGIN_USER } from "../../../router/store/types";
import toast from "react-hot-toast";
import { useMsal } from "@azure/msal-react";

const Header = ({ onLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { instance, accounts } = useMsal();

  // console.log("open", open);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: LOGIN_USER, user: "", name: "" });
    localStorage.setItem("user", "");
    toast.success("Sessão encerrada");
    onLogout();

    instance.logoutPopup().catch((e) => {
      console.error(e);
    });

    navigate("/");
  };

  const handleNavigate = (url) => {
    navigate(url);
    handleClose();
  };

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const name = useSelector((state) => state.name);

  const redirectToPage = () => {
    if (location.pathname !== "/" && (!user || user === undefined)) {
      navigate("/");
      return;
    }
  };

  return (
    <>
      {user ? (
        <>
          <div>
            <div style={{ display: "flex", gap: "10px" }}>
              <FaceIcon />
              {name} ({user})
            </div>
          </div>

          <div className="mui-menu">
            <Button aria-haspopup="true" onClick={handleClick}>
              <MenuIcon />
            </Button>

            <Menu
              id="small-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleNavigate("/proposals")}>
                Emissão de Proposta
              </MenuItem>

              <MenuItem onClick={() => handleNavigate("/stores")}>
                Lojas
              </MenuItem>

              <MenuItem onClick={() => handleNavigate("/assets")}>
                Ativos
              </MenuItem>
              
              <MenuItem onClick={() => handleNavigate("/assets/store")}>
                Ativos por Loja
              </MenuItem>
              
              <MenuItem onClick={() => handleNavigate("/industries")}>
                Indústrias
              </MenuItem>
              {/* <MenuItem
                onClick={() => handleNavigate("/vtex-inventory/search")}
              >
                Consulta Inventário VTEX
              </MenuItem> */}
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </div>
        </>
      ) : (
        redirectToPage()
      )}
    </>
  );
};

export default Header;
