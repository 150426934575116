// App.js
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import {
  ApolloProvider,
  gql,
  useLazyQuery,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from "@apollo/client";
import FilterForm from "./FilterForm";
import ResultTreeView from "./ResultTreeView.js";
import { Container, Typography } from "@mui/material";
import createApolloClient from "../../../../apolloClient";

const App = () => {
  const [client, setClient] = useState();
  const [products, setProducts] = useState([]);
  // const [getProducts, { loading, data }] = useLazyQuery(GET_PRODUCTS, {
  //   client,
  // });

  const [loading, setLoading] = useState(false);

  const getGraphqlData = () => {
    const url = "";
    const payload = "";

    return { url, payload };
  };

  const handleSearch = ({ bandeira, tipo, vtexid, rms }) => {
    let field = "";
    let values = [];

    setLoading(true);

    console.log(bandeira, tipo, vtexid, rms);

    if (vtexid !== undefined && vtexid !== "") {
      vtexid = vtexid.filter((item) => item !== "");

      if (vtexid.length > 0) {
        field = "id";
        values = vtexid;
      }
    }

    if (rms !== undefined && rms !== "") {
      rms = rms.filter((item) => item !== "");

      if (rms.length > 0) {
        field = "reference";
        values = rms;
      }
    }

    if (values.length === 0) {
      toast.error("Informe um Vtex Id ou um código RMS!");
      return;
    }

    const _client = createApolloClient(bandeira, tipo);

    setClient(_client);

    const GET_PRODUCTS = gql`
      query ProductQuery(
        $field: vtex_searchgraphql_0_61_0_ProductUniqueIdentifierField!
        $values: [ID!]!
      ) {
        productsByIdentifier(field: $field, values: $values)
          @context(provider: "vtex.search-graphql") {
          brand
          categoryTree {
            id
            name
            slug
            href
          }
          productId
          productReference
          productName
          link
          linkText
          items {
            itemId
            ean
            sellers {
              sellerDefault
              commertialOffer {
                Price
                ListPrice
                AvailableQuantity
                spotPrice
              }
            }
            images {
              imageId
              imageLabel
              imageTag
              imageUrl
              imageText
            }
          }
          properties {
            originalName
            name
            values
          }
        }
      }
    `;

    // Usar client.query para fazer a chamada
    _client
      .query({ query: GET_PRODUCTS, variables: { field, values } })
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
        console.log("response", response.data);
      })
      .catch((error) => {
        setProducts(error.toString());
        console.error(error.toString());
        setLoading(false);
      });

    // console.log("data", data);

    // getProducts({ variables: filters });
  };

  return (
    <React.Fragment>
      <h2>Consulta Inventário VTEX</h2>

      <FilterForm onSearch={handleSearch} />

      {client ? (
        <ApolloProvider client={client}>
          <Container>
            {loading ? (
              <Typography>Carregando...</Typography>
            ) : (
              <ResultTreeView data={products} />
            )}
          </Container>
        </ApolloProvider>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default App;
