import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { onAuthStateChanged } from "firebase/auth";
import { useForm } from "react-hook-form";

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import {hasFullPermission} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";

import Box from "@mui/material/Box";

const Industry = ({ firebaseApp }) => {
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const theme = useTheme();

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [newDialog, setNewDialog] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [acronymError, setAcronymError] = useState(false);
  const [lastIdError, setLastIdError] = useState(false);

  const user = useSelector((state) => state.user);
  const name = useSelector((state) => state.name);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/industry`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const handleNewClick = () => {
    let row = {
      id: "",
      name: "",
      acronym: "",
      last_id: 0,
    };

    setNewDialog(true);
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const handleEditClick = (row) => {

    console.log(currentRow, row)

    setCurrentRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
  };

  const handleChange = (event, _id) => {
    let { value } = event.target;

    if (_id === 'last_id') value = parseInt(value.replace(',', '').replace('.', ''))

    setCurrentRow((prevRow) => ({ ...prevRow, [_id]: value }));
  };

  const handleKeyDown = (event) => {
    // Bloqueia a vírgula (,) e o ponto (.)
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
    }
  };

  const handleSave = async () => {
    if (newDialog) {
      
      setLoading(true);

      let formData = {
        ...currentRow,
        name: currentRow.name.toUpperCase(),
        acroymn: currentRow.acronym.toUpperCase(),
        inc_user_id: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .post(`${apiUrl}/api/v1/industry`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 201) {
            toast.success('Indústria incluída com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
      
    } else {
      setLoading(true);

      let formData = {
        ...currentRow
      }

      console.log("Formulário enviado:", formData);

      axios
        .put(`${apiUrl}/api/v1/industry/${currentRow.id}`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            toast.success('Indústria atualizada com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  /*
      {
      field: "campaign",
      headerName: "Campanha",
      flex: 1,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
  */

  const columns = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      editable: false,
    },
    {
      field: "acronym",
      headerName: "Sigla",
      flex: 1,
      editable: false,
    },
    {
      field: "last_id",
      headerName: "Últ. ID",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton aria-label="delete" color="primary" disabled>
            <DeleteIcon />
          </IconButton> */}
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <h2>Indústrias</h2>

      {hasFullPermission(user) && <div
        style={{
          marginLeft: "0px",
          display: "flex",
          justifyContent: "right",
          gap: "40px",
          width: "100%",
          marginBottom: "25px",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "200px" }}
          startIcon={<AddIcon />}
          onClick={() => {
            handleNewClick();
          }}
        >
          Novo
        </Button>
      </div>}

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={searchResults}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 50]}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
        {/* 
        loading fullscreen
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>


      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>{newDialog ? "Nova" : "Editar"} Indústria</DialogTitle>
        <DialogContent>
          {currentRow && (
            <>
              <Grid
                container
                direction={'column'}
                style={{ marginBottom: "0px" }}
              >
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.name}
                  >
                    <TextField
                      margin="dense"
                      name="name"
                      label="Nome"
                      type="text"
                      fullWidth
                      value={currentRow.name}
                      error={!!errors.name}
                      {...register("name", {
                        required: "Campo Obrigatório",
                      })}
                      onChange={(e) => handleChange(e, "name")}
                    />
                    {errors.name && (
                      <FormHelperText>{errors.name.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.acronym}
                  >
                    <TextField
                      margin="dense"
                      name="acronym"
                      label="Sigla"
                      type="text"
                      fullWidth
                      value={currentRow.acronym}
                      error={!!errors.acronym}
                      {...register("acronym", {
                        required: "Campo Obrigatório",
                      })}
                      onChange={(e) => handleChange(e, "acronym")}
                    />
                    {errors.acronym && (
                      <FormHelperText>{errors.acronym.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.last_id}
                  >
                    <TextField
                      margin="dense"
                      name="last_id"
                      label="Últ. ID"
                      type="number"
                      fullWidth
                      value={currentRow.last_id}
                      error={!!errors.last_id}
                      {...register("last_id", {
                        required: "Campo Obrigatório",
                      })}
                      onChange={(e) => handleChange(e, "last_id")}
                      onKeyDown={handleKeyDown}
                    />
                    {errors.last_id && (
                      <FormHelperText>{errors.last_id.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          {hasFullPermission(user) && <Button onClick={handleSubmit(handleSave)} color="primary">
            Salvar
          </Button>}
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

export default Industry;
