import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip
} from "@mui/material";

import PreviewIcon from '@mui/icons-material/Preview';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

import { DataGrid } from "@mui/x-data-grid";

import { formatCurrency, formatDate } from "../../../../router/store/types";

function ProposalSummary({ data, edit }) {

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {

    console.log('data', data)

    setLoading(false)

  }, []);

  const columns = [
    {
      field: "loja",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.sigla} - {params.row.loja}</>
      )
    },
    {
      field: "asset",
      headerName: "Ativo",
      flex: 1,
      width: 150
    },
    {
      field: "qty",
      headerName: "Disponível",
      width: 120
    },
    {
      field: "qty_proposta",
      headerName: "Qtde. Proposta",
      width: 140,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px' }}>{params.row.qty_proposta}</div>
      )
    },
    {
      field: "price_per_asset",
      headerName: "Preço por Ativo",
      width: 150,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 150,
      valueGetter: (params) => params.row.qty_proposta * params.row.price_per_asset,
      renderCell: (params) => (
        <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(params.value)}</div>
      ),
    },
  ];

  return (
    <>
      {!edit &&
        <Typography sx={{ fontWeight: "bold", fontSize: "16px", display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "10px" }}>
          <ChecklistRtlIcon color="primary" style={{ fontSize: 50, marginRight: 10 }} /><div>Verifique os dados da proposta</div>
        </Typography>
      }

      <Box sx={{ padding: 1, height: (!edit ? "calc(100vh - 370px)": "calc(100vh - 250px)") , overflowY: "scroll", overflowX: "hidden" }}>
        
        <div style={{display: 'flex', justifyContent: 'space-between'}}>

          <fieldset className="summary-field" style={{ width: '100%' }}>
            <legend>Cliente</legend>
            <div>{data.proposal.client}</div>
          </fieldset>

          {data.proposal.inc_date &&
            <fieldset className="summary-field" style={{ width: '180px' }}>
              <legend>Data da Proposta</legend>
              <div>{formatDate(data.proposal.inc_date)}</div>
            </fieldset>
          }

        </div>

        <fieldset className="summary-field">
          <legend>Contato</legend>
          <div>{data.proposal.clientContact}</div>
        </fieldset>

        <fieldset className="summary-field">
          <legend>Campanha</legend>
          <div>{data.proposal.campaign}</div>
        </fieldset>

        {data.proposal.comments && <fieldset className="summary-field">
            <legend>Observações</legend>
            <div>{data.proposal.comments}</div>
          </fieldset>
        }

        <div style={{display: "flex", justifyContent: "space-between"}}>
          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Mídia</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(data.proposal.totalMidia)}</div>
          </fieldset>

          <fieldset className="summary-field" style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Custos Operação</legend>
            <div style={{ width: "50%", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(data.proposal.totalCOGS)}</div>
          </fieldset>
        </div>
        
        <Box sx={{ marginTop: "15px" }}>
          <DataGrid
            rows={data.assets}
            columns={!edit ? columns : columns.filter((el) => el.field !== 'qty')}
            loading={loading}
            autoHeight
            disableRowSelectionOnClick
            hideFooter
            localeText={{
              noRowsLabel: "Nada encontrado",
            }}
            getRowId={(row) => row.id}
            sx={{
              marginRight: "20px",
              ".MuiDataGrid-overlayWrap": {
                height: "55px !important",
                fontWeight: "bold",
              },
              ".MuiDataGrid-overlayWrapperInner": {
                height: "55px !important",
                fontWeight: "bold",
              },
              ".MuiDataGrid-overlayWrapper": {
                height: "55px !important",
                fontWeight: "bold",
              },
              fontSize: "10px", // Tamanho da fonte reduzido
              "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                fontSize: "12px", // Tamanho da fonte reduzido para cabeçalho e células
                padding: "5px", // Espaçamento interno reduzido
              },
            }}
          />
        </Box>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <fieldset className="summary-field" style={{ width: "200px", fontSize: '18px', fontWeight: 'bold', color: '#090059' }}>
            <legend>Total Proposta</legend>
            <div style={{ width: "100%", textAlign: 'right', paddingRight: "10px", fontSize: '20px', fontWeight: 'bold', color: 'red' }}>{formatCurrency(parseFloat(data.proposal.totalCOGS) + parseFloat(data.proposal.totalMidia))}</div>
          </fieldset>
        </div>

      </Box>
    </>
  );
}

export default ProposalSummary;
