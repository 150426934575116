import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { formatCurrency } from "../../router/store/types";
import CurrencyTextField from "../CurrencyTextField";

function StoreAssetGrid({ rows, storesAssetsSelected, cogs, callback, callbackCOGS, callbackMidia, edit }) {

  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);

  const [total, setTotal] = useState(0);
  const [totalCOGS, setTotalCOGS] = useState(0);

  const calcTotal = (_rows) => {
    const totalSum = _rows.reduce((sum, row) => sum + (row.qty_proposta * row.price_per_asset), 0);
    setTotal(totalSum)
    callbackMidia(totalSum)
  }

  const handleChangeCOGS = (values) => {
    const { value } = values;
    callbackCOGS(value)
  };

  const handleValueChange = (e, params) => {
    const newValue = Number(e.target.value);

    let value = Math.floor(e.target.value) > Math.floor(params.row.qty) ? params.row.qty : e.target.value;
    params.api.setEditCellValue({ id: params.id, field: 'qty_proposta', value: Math.floor(value) });

    let _rows = items.map((item) =>
      item.id === params.row.id ? { ...item, qty_proposta: newValue } : item
    )

    setItems(_rows);
    calcTotal(_rows)

    callback(_rows)
  };

  useEffect(() => {

    console.log('storesAssetsSelected', storesAssetsSelected)

    setLoading(true)

    if (storesAssetsSelected)
      setSelected(storesAssetsSelected)

    setItems(rows)

    calcTotal(rows)

    setTotalCOGS(cogs)

    setLoading(false)

  }, [rows]);

  const columns = [
    {
      field: "loja",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>{params.row.sigla} - {params.row.loja}</>
      )
    },
    {
      field: "asset",
      headerName: "Ativo",
      flex: 1,
      width: 150
    },
    {
      field: "qty",
      headerName: "Disponível",
      width: 120
    },
    {
      field: "qty_proposta",
      headerName: "Qtde. Proposta",
      width: 140,
      editable: true,
      renderCell: (params) => (
        <div style={{cursor: 'pointer', fontSize: '16px', fontWeight: 'bold', width: '80px'}}>✏️ {params.row.qty_proposta}</div>
      ),
      renderEditCell: (params) => (
        <TextField
          type="number"
          inputProps={{
            step: 1,  // Força valores inteiros
            min: 0,
            max: params.row.qty,
            pattern: "[0-9]*"  // Impede a entrada de não-números
          }}
          value={params.value}
          onChange={(event) => handleValueChange(event, params)}
        />
      ),
    },
    {
      field: "price_per_asset",
      headerName: "Preço por Ativo",
      width: 150,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: 'total',
      headerName: 'Total',
      width: 150,
      valueGetter: (params) => params.row.qty_proposta * params.row.price_per_asset,
      renderCell: (params) => (
        <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#090059' }}>{formatCurrency(params.value)}</div>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ padding: 1, height: "calc(100vh - 450px)", overflowY: "scroll", overflowX: "hidden" }}>
        <DataGrid
          rows={items}
          columns={columns}
          loading={loading}
          autoHeight
          disableRowSelectionOnClick
          hideFooter
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          getRowId={(row) => row.id}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
            fontSize: "10px", // Tamanho da fonte reduzido
            "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
              fontSize: "12px", // Tamanho da fonte reduzido para cabeçalho e células
              padding: "5px", // Espaçamento interno reduzido
            },
          }}
        />
      </Box>
      <>
        {items.length > 0 && (
          <>
            {/* Linha de total */}
            <div style={{ width: "100%", textAlign: "right", display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
              <div style={{ fontSize: '18px', fontWeight: 'bold', color: '#090059', marginRight: "10px" }}>Total: {total > 0 ? formatCurrency(total) : formatCurrency(0)}</div>
            </div>

            <div style={{ width: "100%", textAlign: "right", display: 'flex', justifyContent: 'space-between', padding: '8px' }}>

              <div style={{ fontWeight: "bold", fontSize: '12px', display: 'block', textAlign: 'center', border: '1px solid #ccc', width: '300px', padding: '10px', backgroundColor: 'lightyellow' }}>
                <div>Clique duplo para editar a quantidade</div>
                <div style={{ marginTop: '10px' }}>⚠️ Não integrado ao Tegra nesta versão</div>
              </div>

              <div style={{ width: "250px" }}>
                <CurrencyTextField label={'Total Custos Operação'} value={totalCOGS} onChange={handleChangeCOGS} />
              </div>
            </div>
          </>
        )}
      </>
    </>
  );
}

export default StoreAssetGrid;
