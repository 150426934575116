import React, { useState, useEffect } from "react";

import {
  Button,
  Checkbox,
  Box,
  TextField,
  Grid,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { TIPOS_ATIVOS } from "../../../../router/store/types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Lookup = ({ retailer, assets, returnFunc }) => {
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState([]);
  const [type, setType] = useState("Todos");
  const [asset, setAsset] = useState("");

  const [columns, setColumns] = useState([]);

  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionChange = (newSelection) => {
    setSelectionModel(newSelection);
  };

  useEffect(() => {
    setType("Todos");
    setAsset("");
    filterRows();
  }, [retailer]);

  useEffect(() => {
    filterRows();
  }, [type, asset]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const buildColumns = () => {
    /*
    Adesivo de Esteira[nome]
    Adesivo de Esteira[preco]
    Adesivo de Esteira[qtd]
    Mini Outdoor[nome]
    Mini Outdoor[preco]
    Mini Outdoor[qtd]
    loja
    retailer
    sigla
    */
    let _columns = [
      {
        field: "sigla",
        headerName: "Sigla",
        width: 100,
      },
      {
        field: "loja",
        headerName: "Loja",
        width: 100,
      },
    ];

    const names = Object.keys(assets[0]);
    names.forEach((el) => {
      if (el.includes("[preco]")) {
        _columns.push({
          field: el,
          headerName: el.includes("[preco]")
            ? "Preço por Ativo"
            : el.includes("[qtd]")
            ? "Qtde."
            : el,
          width: 120,
          valueFormatter: (params) => formatCurrency(params.value),
        });
      } else if (el !== "sigla" && el !== "loja" && el !== "retailer") {
        _columns.push({
          field: el,
          headerName: el.includes("[preco]")
            ? "Preço por Ativo"
            : el.includes("[qtd]")
            ? "Qtde."
            : el.includes("[nome]")
            ? el.replace("[nome]", "")
            : el,
          width: el.includes("[preco]") ? 120 : el.includes("[qtd]") ? 90 : 120,
          renderCell: (params) =>
            params.value === "" ||
            params.value === null ||
            params.value === undefined
              ? "-"
              : params.value,
        });
      }
    });
    setColumns(_columns);
  };

  const filterData = (array, retailer, type = null, asset = null) => {
    buildColumns();
    return array.filter((item) => {
      const matchRetailer = item.retailer === retailer;
      const matchType =
        type !== null && type !== "Todos" ? item.type === type : true;
      const matchAsset =
        asset !== null && asset.trim() !== ""
          ? item.sigla.toString().toLowerCase().includes(asset.toLowerCase()) ||
            item.loja.toString().toLowerCase().includes(asset.toLowerCase())
          : true;
      return matchRetailer && matchType && matchAsset;
    });
  };

  const filterRows = () => {
    const rows = filterData(assets, retailer, type, asset);
    setRows(rows);
    setLoading(false);
  };

  const handleAddClick = () => {
    const selectedRows = rows.filter((row) =>
      selectionModel.includes(row.sigla)
    );
    // console.log(selectedRows);
    returnFunc(selectedRows);
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    setType(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAsset(value);
  };

  return (
    <>
      <div style={{ width: "100%", marginBottom: "30px" }}>
        <>
          <Grid container alignItems="center" style={{ marginBottom: "0px" }}>
            <Grid item xs={5}>
              <h5>Selecione os ativos para a proposta</h5>
            </Grid>

            <Grid xs={5} sx={{ textAlign: "right" }}>
              <FormControl
                sx={{
                  width: "200px",
                  marginRight: "10px",
                  marginBottom: "0px",
                }}
              >
                <TextField
                  label="Ativo"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            {/* <Grid xs={2}>
              <FormControl
                sx={{
                  marginLeft: "0px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  minWidth: 180,
                }}
              >
                <InputLabel id="type-label">Tipo</InputLabel>
                <Select
                  labelId="type-label"
                  id="type-cmb"
                  value={type}
                  onChange={(e) => handleChangeType(e)}
                  input={<OutlinedInput label="Tipo" />}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="Todos">Todos</MenuItem>
                  {TIPOS_ATIVOS.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item xs={2} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                style={{ width: "120px" }}
                startIcon={<PlaylistAdd />}
                onClick={() => {
                  handleAddClick();
                }}
              >
                Incluir
              </Button>
            </Grid>
          </Grid>
        </>
        <div>
          {rows.length > 0 ? (
            <DataGrid
              rows={rows}
              columns={columns}
              loading={loading}
              rowSpacing={2}
              rowHeight={30}
              autoHeight
              sx={{
                fontSize: "11px", // Tamanho da fonte reduzido
                "& .MuiDataGrid-columnHeaderTitle": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  lineHeight: "1.2em",
                  whiteSpace: "normal",
                },
                "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                  fontSize: "11px", // Tamanho da fonte reduzido para cabeçalho e células
                  padding: "4px", // Espaçamento interno reduzido
                  outline: "transparent",
                  cursor: "pointer",
                },
                [`& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "sigla", sort: "asc" }],
                },
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[15]}
              getRowId={(row) => row.sigla}
              checkboxSelection
              onRowSelectionModelChange={(newSelectionModel) => {
                handleSelectionChange(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          ) : (
            <>
              <h5>Não foi localizado nenhum ativo</h5>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Lookup;
