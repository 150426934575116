import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Typography,
} from "@mui/material";

import html2pdf from "html2pdf.js";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from "@mui/icons-material/Print";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import { formatCurrency, formatDate } from "../../../../router/store/types";

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// Documento PDF
const MyDocument = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Seção 1</Text>
      </View>
      <View style={styles.section}>
        <Text>Seção 2</Text>
      </View>
    </Page>
  </Document>
);

function ProposalPdf({ data, edit }) {

  const [loading, setLoading] = useState(true);
  
  useEffect(() => {

    console.log('data', data)

    setLoading(false)

  }, []);

  const getHtmlData = (channel) => {

    const _data = { ...data.proposal, assets: data.assets };

    const columns = [
      {
        field: "loja",
        headerName: "Loja",
      },
      {
        field: "asset",
        headerName: "Ativo",
      },
      {
        field: "qty_proposta",
        headerName: "Qtde. Proposta",
        width: "80px",
        align: "right"
      },
      {
        field: "price_per_asset",
        headerName: "Preço da Visibilidade por Ativo",
        width: "100px",
        align: "right"
      },
      {
        field: 'total',
        headerName: 'Total',
        width: "100px",
        align: "right"
      },
    ];

    let assets_table = `<tbody>`;

    let totalMidia = 0;

    _data.assets.forEach((row, index) => {
      if (row['qty_proposta'] > 0) {
        assets_table += `<tr>`;
        row = { ...row, total: 0 }; // não vem por default

        columns.forEach((col) => {
          Object.keys(row).forEach((el) => {
            if (el === col.field) {
              if (el.includes("total")) {
                totalMidia += parseFloat(row['qty_proposta']) * parseFloat(row['price_per_asset'])
                assets_table += `<td style="${col.align ? `text-align: ${col.align};padding-right: 5px;` : ''}">${formatCurrency(parseFloat(row['qty_proposta']) * parseFloat(row['price_per_asset']))}</td>`;
              }
              else if (el.includes("price_per_asset")) {
                assets_table += `<td style="${col.align ? `text-align: ${col.align};padding-right: 5px;` : ''}">${formatCurrency(row[el])}</td>`;
              }
              else if (el.includes("loja")) {
                assets_table += `<td style="${col.align ? `text-align: ${col.align};padding-right: 5px;` : ''}">${row['sigla']} - ${row[el]}</td>`;
              } else {
                let val = row[el];
                if (val === "" || val === null || val === undefined)
                  assets_table += `<td>-</td>`;
                else assets_table += `<td style="${col.align ? `text-align: ${col.align};padding-right: 5px;` : ''}">${val}</td>`;
              }
            }
          });
        });

        assets_table += `</tr>\n`;
      }
    });

    assets_table += `<tr style="background-color: #ddd;">`;
    assets_table += `<td colspan="4" style="font-size: 16px !important; text-align: right;padding: 5px;"><b>Total da Proposta</b></td>`;
    assets_table += `<td style="font-size: 16px !important; text-align: right;padding: 5px;"><b>${formatCurrency(parseFloat(_data.totalCOGS) + totalMidia)}</b></td>`;
    assets_table += `</tr>\n`;

    assets_table += `</tbody>`;

    const notes = _data.comments
      ? `<div style="font-size: 12px; margin-top: 30px">
          <div
            style="flex: 1; textAlign: left;font-size: 12px;">
            <b>Observações</b>
          </div>
          <div
            style="width: 100%;
              font-size: 12px">
            ${_data.comments}
          </div>
        </div>`
      : "";

    let header = "";

    columns.forEach((col) => {
      let el = col.headerName;
      header += `<th style="${col.width ? `width: ${col.width};` : ''}">${el}</th>`;
    });

    const content = `
      <html>

      <head>
      <style>
        ${channel === 'web' ? `body div, body th, body td { font-family: "Century Ghotic", "Helvetica", "Arial", sans-serif; font-size: 16px !important; }` : ""}

        body {
          font-family: "Roboto","Helvetica","Arial",sans-serif;
          font-size: 16px;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 9px;
          page-break-inside:auto;
        }

        tr { page-break-inside:avoid; page-break-after:auto; }

        thead th {
          background-color: #090059;
          padding: 1px;
          border: 1px solid #fff;
          color: #fff;
          font-weight: bold;
          font-size: 12px;
        }

        tbody td {
          padding: 1px;
          border: 1px solid #ddd;
          font-size: 11px;
        }

        thead {
          display: table-header-group; 
        }
      </style>
      </head>

      <body>

      <!-- CAPA -->
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: #36f6a9;
          padding: 10px;
          height: 297mm;
          width: 100%;
          margin: 0;
          padding: 0;
        "
      >
        <div style="text-align: center; margin-top: 30px;display: flex; justify-content: center;">
          <img src="/logo-unlimitail-azul.png" width="600" alt="" />
        </div>

        <div style="text-align: center; margin-top: 60px">
          <h1
            style="
              color: #090059;
              font-size: 46px;
              text-align: center;
              margin: 0;
              padding: 0;
            "
          >
            Proposta
            <br>
            ${_data.client}
          </h1>

          <h2
            style="
              color: #090059;
              font-size: 24px;
              text-align: center;
              margin: 0;
              padding: 0;
            "
          >
            ${_data.campaign}
          </h2>
          <h3
            style="
              color: #090059;
              font-size: 14px;
              text-align: center;
              margin: 0;
              padding: 0;
            "
          >
            ${formatDate(_data.inc_date)}
            <BR/><BR/>
            ${_data.proposal_id.toString()}
          </h3>
        </div>

        <div style="display: flex; justify-content: center;">
          <img src="/illu_home.png" width="580" alt="" />
        </div>
      </div>

      <!-- PROPOSTA -->
      <header style="display: flex; justify-content: space-between; background-color: #090059; padding: 10px;">
        <div>
          <img src="/logo-unlimitail.png" width="120" alt="" />
        </div>
        <div style="text-align: center;margin-top: -5px">
          <h1 style="color: #ffffff; font-size: 14px;text-align: right; margin: 0; padding: 0">Proposta</h1>
          <div style="color: #ffffff;font-size: 10px;">versão ${new Date().toLocaleString(
      "pt-BR",
      { hour12: false }
    )}</div>
        </div>
      </header>

      <div style="margin: 20px;">
      
        <div style="display: flex; align-items: center; justify-content: space-between; text-align: right;margin-bottom: 10px;">

          <div style="margin-top: 0px;">
            <div>
              ${_data.retailer.startsWith("Carrefour")
      ? `<img width="60" src="/carrefour-logo-200.png" />`
        : _data.retailer === "Atacadão"
          ? `<img height="40" src="/atacadao-logo-200.png" />`
          : _data.retailer === "Express"
            ? `<img height="40" src="/express-logo-200.png" />`
            : `<img width="60" src="/sams-logo-200.png" />`
      }
            </div>
          </div>

          <div style="font-size: 12px;">São Paulo, ${formatDate(_data.inc_date)}</div>
        </div>

        <div>
          <div style="font-size: 12px;">
            <b>À</b>
          </div>
          <div style="font-size: 14px; width: 100%;">
            <b>${_data.client}</b>
          </div>

          <div style="display: flex; margin-top: 10px">
            <div style="font-size: 12px;margin-right: 5px;">
              <b>A/C:  </b>
            </div>
            <div style="font-size: 12px; width: 100%;">
              ${_data.clientContact}
            </div>
          </div>

          <div style="display: flex; margin-top: 10px">
            <div style="font-size: 12px; margin-right: 5px; text-decoration: underline; font-weight: bold;">
              Ref.: Proposta ${_data.proposal_id} de Prestação de Serviços  = ${_data.campaign}
            </div>
          </div>

          <div style="display: flex; margin-top: 10px">
            <div style="font-size: 12px; margin-right: 5px;">
              <b>Sales Manager </b>
            </div>
            <div style="font-size: 12px;">
              ${_data.inc_user_id.replace(/</g, '&lt;').replace(/>/g, '&gt;')}
            </div>
          </div>

          ${
          _data.totalCOGS > 0 ? `<div style="display: flex; margin-top: 10px">
            <div style="font-size: 12px; margin-right: 5px; width: 300px">
              <b>Total dos Custos de Operação:  </b>
            </div>
            <div style="font-size: 12px; width: 100%;">
              <b>${formatCurrency(_data.totalCOGS)}</b>
            </div>
          </div>` : ``
          }

        </div>

        <div style="font-size: 12px;margin-top: 20px;margin-bottom: 20px;">
            Conforme solicitação, segue proposta para a prestação de serviços de veiculação de publicidade, nos termos e condições a seguir descritos. 
        </div>

        <div style="margin: 5px; width: 100%">
          <table>
                <thead>
                  <tr>
                    ${header}
                  </tr>
                </thead>
                ${assets_table}
          </table>
        </div>

        ${_data.comments ? notes : ""}
        
        <!-- CONDIÇÕES -->
        <p><br/></p>
        <div style="font-size: 12px;margin-top: 40px;margin-bottom: 20px;page-break-before: always; text-align: justify;">
            <p><b>1. <u>PREÇO E FORMA DE PAGAMENTO </u></b></p>
            <p><b>1.1.</b> O Cliente pagará o Preço descrito acima no prazo de 30 dias da data da emissão da Nota Fiscal. O pagamento efetuado com atraso implicará nos acréscimos de multa de 2% (dois por cento), atualização monetária pró-rata die pelo IGP-M/FGV e juros de mora de 1% (um por centos) ao mês ou fração e serão incluídos no relatório do mês subsequente à ocorrência. </p>
            <p><b>1.2.</b> Caso o Cliente venha a desistir da Campanha até 15 (quinze) dias antes do início desta, a Unlimitail fará a devolução do valor eventualmente pago no importe de 50%, descontado o valor dos impostos.  </p>
            <p><b>1.3.</b> O Cliente deverá informar no ato do aceite da Proposta os dados que devem constar da Nota Fiscal, bem como o destinatário para envio desta. </p>
        </div>

        <div style="font-size: 12px;margin-top: 5px;margin-bottom: 20px; text-align: justify;">
            <p><b>2. <u>MATERIAIS DO CLIENTE </u></b></p>
            <p><b>2.1.</b> Para a prestação de serviços pela Unlimitail, o Cliente deverá fornecer (diretamente ou por meio de seus fornecedores) ativos criativos, imagens e outros conteúdos de marketing ou informativos, incluindo mensagens, ofertas, avaliações, fontes, logotipos  (“Conteúdo de Marketing”). O Cliente será responsável por toda a revisão legal, aprovação e liberação para uso do Conteúdo de Marketing  a ser utilizado pela Unlimitail na prestação de serviços. </p>
            <p><b>2.2.</b> Caso o Cliente contrate a instalação de mídia "in store" e opte pela instalação direta de materiais, será aplicável à ele a cláusula 3 abaixo.</p>
        </div>

        <div style="font-size: 12px;margin-top: 5px;margin-bottom: 20px; text-align: justify;">
            <p><b>3. <u>IMPLANTAÇÃO DA MÍDIA PELO PRÓPRIO CLIENTE</u></b></p>
            <p><b>3.1.</b> Caso o Cliente opte pela implantação direta da mídia, este ficará responsável pela  (i) produção, (ii) instalação, (iii) desinstalação e retirada dos materiais de mídia (Campanha) acordados na Proposta, diretamente na (s) Loja(s) lá mencionada(s), usando sua própria mão de obra ou de seus fornecedore, devendo respeitar os regulamentos internos e normas de segurança das Lojas, bem como as especificações determinadas pela área de operações da Unlimitail. </p>
            <p><b>3.2.</b> Caso o Cliente não efetue a desinstalação e retirada dos materiais da Campanha dentro do prazo estipulado na Proposta este ficará sujeito à uma multa diária no valor de 20% (vinte por cento) do Valor da Campanha  até a completa retirada dos materiais.</p>
        </div>

        <div style="font-size: 12px;margin-top: 5px;margin-bottom: 20px; text-align: justify;">
            <p><b>4. <u>PRAZO DE ACEITAÇÃO E OUTRAS CONDIÇÕES </u></b></p>
            <p><b>4.1.</b> A presente proposta terá validade de 10 (dez) dias contados da data de sua emissão.</p>
            <p><b>4.2.</b> Os Serviços poderão ser contratados diretamente pelo Anunciante ou por uma Agência agindo em nome do Anunciante. No caso em que o Anunciante tenha confiado a sua atividade publicitária a uma Agência, este compromete-se a fornecer à Unlimitail um termo que confirme o mandato concedido à Agência e autorizando-a a realizar pedidos em nome do Anunciante. O Anunciante compromete-se a notificar por escrito a Unlimitail de qualquer alteração ou extinção do mandato concedido a uma Agência ou Subagência. </p>
        </div>

      </div>
            
      </body>
      </html>
    `;

    return content;
  }

  const openWindow = (e) => {
    e.preventDefault();

    const content = getHtmlData('web');

    const element = document.createElement("div");
    element.innerHTML = content;

    const newWindow = window.open('', '_blank', 'width=1024,height=1024');
    if (newWindow) {
      newWindow.document.write(content);
      newWindow.document.close();
    }
  }

  const generatePDF = (e) => {
    e.preventDefault();

    const content = getHtmlData('pdf');

    const element = document.createElement("div");
    element.innerHTML = content;

    const opt = {
      filename: `proposta_${data.proposal.proposal_id}.pdf`,
    };

    html2pdf().from(element).set(opt).save();
  };

  if(!edit)
    return (
      <>
        <Typography sx={{ fontWeight: "bold", fontSize: "16px", display: "flex", alignItems: "center", justifyContent: "flex-start", marginLeft: "10px" }}><PictureAsPdfIcon color="primary" style={{ fontSize: 50, marginRight: 10 }} /><div>Gerar documentos da Proposta { data.proposal.proposal_id }</div></Typography>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", height: "calc(100vh - 400px)", paddingTop: 15 }}>
          
          {/* <MyDocument /> */}
          
          <Button
            variant="contained"
            color="error"
            type="submit"
            size="large"
            style={{ marginRight: "20px" }}
            startIcon={<PrintIcon />}
            onClick={(e) => generatePDF(e)}
          >
            Gerar PDF
          </Button>

          <Button
            variant="outlined"
            color="error"
            type="submit"
            size="large"
            startIcon={<OpenInBrowserIcon />}
            onClick={(e) => openWindow(e)}
          >
            Imprimir
          </Button>
          
        </Box>

        <></>

        {/* <PDFDownloadLink document={<MyDocument />} fileName={`proposta-${data.proposal.proposal_id}.pdf`}>
          {({ blob, url, loading, error }) =>
            loading ? 'Gerando PDF...' : 'Baixar PDF'
          }
        </PDFDownloadLink> 
        
        <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="large"
                      startIcon={<PrintIcon />}
                      onClick={(e) => generatePDF(e)}
                    >
                      Imprimir
                    </Button>
        
        */}
      </>
    );
  else {
    return (
      <><Button
        variant="contained"
        color="error"
        type="submit"
        size="large"
        style={{ marginRight: "20px" }}
        startIcon={<PrintIcon />}
        onClick={(e) => generatePDF(e)}
      >
        Gerar PDF
      </Button>

        <Button
          variant="outlined"
          color="error"
          type="submit"
          size="large"
          startIcon={<OpenInBrowserIcon />}
          onClick={(e) => openWindow(e)}
        >
          Imprimir
        </Button>
      </>
    )
  }
}

export default ProposalPdf;
