// ResultTreeView.js
import React from "react";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";

const renderTree = (nodes, parentId = "") => {
  return Object.keys(nodes).map((key, index) => {
    const value = nodes[key];
    const currentId = `${parentId}${key}-${index}`; // Gera um ID único baseado na hierarquia

    if (typeof value === "object" && value !== null) {
      return (
        <TreeItem
          key={currentId}
          nodeId={currentId}
          label={<Typography variant="body2">{key}</Typography>}
        >
          {renderTree(value, `${currentId}-`)}
        </TreeItem>
      );
    }
    return (
      <TreeItem
        key={currentId}
        nodeId={currentId}
        label={<Typography variant="body2">{`${key}: ${value}`}</Typography>}
      />
    );
  });
};

const ResultTreeView = ({ data }) => {
  const getItemId = (item) => {
    return item.nodeId; // Use a lógica necessária para gerar um ID único
  };

  const jsonString = JSON.stringify(data, null, 2); // 2 é para a indentação

  return (
    <>
      {/* <SimpleTreeView aria-label="dynamic tree" getItemId={getItemId}>
        {renderTree(data)}
      </SimpleTreeView> */}
      <TextField
        label="Description"
        variant="outlined"
        value={jsonString}
        InputProps={{
          readOnly: true, // Torna o campo somente leitura
          style: { fontFamily: "monospace", fontSize: "13px" }, // Define a fonte monoespaçada
        }}
        multiline
        sx={{ mb: 6 }}
        fullWidth
        rows={25}
      />
    </>
  );
};

export default ResultTreeView;
