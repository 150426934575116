import * as React from 'react';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { useForm } from "react-hook-form";


export default function CurrencyTextField({ label, value, onChange = (e) => { }, name, required, error }) {
    
    const {
        register,
    } = useForm();

    return (
        <NumericFormat
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            prefix="R$ "
            decimalScale={2}
            fixedDecimalScale
            onValueChange={onChange}
            label={label}
            fullWidth
            value={value}
            error={error}
            name={name}
            required={required}
        />
    );
}
