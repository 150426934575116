import React, { useState, useEffect } from "react";
import { AppBar, Toolbar } from "@mui/material";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Container } from "@mui/system";

import { Toaster } from "react-hot-toast";

import { Provider } from "react-redux";

import store from "./store/store";
import { FIREBASE_CONFIG } from "./store/types";

import ProtectedRoute from "../ProtectedRoute";

import Login from "../screens/Authentication/Login";
import NewRequest from "../screens/Authentication/Request/New";
import Request from "../screens/Authentication/Request";
import Assets from "../screens/Pages/Assets";
import Industries from "../screens/Pages/Industries";
import Loader from "../screens/Pages/Assets/Loader";
import Stores from "../screens/Pages/Stores";
import AssetsStore from "../screens/Pages/AssetsStore";
import Ratecards from "../screens/Pages/Ratecards";

import EditRatecard from "../screens/Pages/Ratecards/New";
import Home from "../screens/Authentication/Home";
import Header from "../screens/Authentication/Header";
import VtexInventorySearch from "../screens/Pages/VtexInventory/Search";

import Proposals from "../screens/Pages/Proposals";
import ProposalForm from "../screens/Pages/Proposals/ProposalForm";
import ProposalFormView from "../screens/Pages/Proposals/ProposalFormView";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  return (
    <React.Fragment>
      <Provider store={store}>
        <AppBar position="static">
          <Toolbar
            color="primary"
            style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
          >
            <div style={{width: "200px"}}>
              <img src="/logo-unlimitail.png" width={200} alt="" />
            </div>
            <Header onLogout={logout} />
          </Toolbar>
        </AppBar>
        <Container>
          <Routes>
            <Route
              element={<ProtectedRoute isAuthenticated={isAuthenticated} />}
            >
              <Route path="/home" element={<Home />} />
              <Route
                path="/industries"
                element={<Industries firebaseApp={app} />}
              />
              <Route path="/assets" element={<Assets firebaseApp={app} />} />
              {/* <Route
                path="/assets/loader"
                element={<Loader firebaseApp={app} />}
              /> */}
              <Route
                path="/assets/store"
                element={<AssetsStore firebaseApp={app} />}
              />
              <Route
                path="/vtex-inventory/search"
                element={<VtexInventorySearch firebaseApp={app} />}
              />
              <Route path="/stores" element={<Stores firebaseApp={app} />} />
              
              <Route
                path="/proposals"
                element={<Proposals firebaseApp={app} />}
              />
              <Route
                path="/proposals/new"
                element={<ProposalForm firebaseApp={app} />}
              />
              <Route
                path="/proposals/:param_proposal_id"
                element={<ProposalFormView  firebaseApp={app} />}
              />

            </Route>

            <Route path="/" element={<Login onLogin={login} />} />
          </Routes>

          <Toaster
            toastOptions={{
              duration: 3000,
            }}
          />
        </Container>
      </Provider>
    </React.Fragment>
  );
}

export default App;
