import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  FormControl,
  Tooltip
} from "@mui/material";

import { BANDEIRAS } from "../../router/store/types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function AssetCheckboxList({ rows, retailerSelected, assetsSelected, callback, callbackSelect, edit }) {
  const [filter, setFilter] = useState("");
  const [retailer, setRetailer] = useState(retailerSelected || "");

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleChangeSelect = async (e) => {
    const { name, value } = e.target;
    setRetailer(value);

    await callbackSelect(value, []);
  };

  const handleChangeCheckbox = async (option) => {
    const { id, checked } = option;

    let _assets = selected;

    if (checked) {
      _assets = selected.filter((el) => el !== id)
    } else {
      _assets.push(parseInt(id));
    }

    setSelected(_assets);

    setOptions((prevOptions) =>
      prevOptions.map((field) =>
        field.id === id ? { ...field, checked: !checked } : field
      )
    );

    await callback(_assets);
  };

  useEffect(() => {

    if (retailerSelected)
      setRetailer(retailerSelected)

    if (assetsSelected)
      setSelected(assetsSelected)
    
    // Filtro inline aplicado ao array de rows
    const filteredRows = rows.filter(
      (row) =>
        (filter === "" ||
          row.title.toLowerCase().includes(filter.toLowerCase())) &&
        (retailer === "" || row.retailer == retailer)
    ).map((el) => ({
      ...el,
      checked: assetsSelected.includes(parseInt(el.id))
    }));

    console.log('filteredRows', filteredRows, assetsSelected)

    setOptions(filteredRows)

  }, [rows, filter, retailer]);

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Grid container alignItems="center" style={{ marginBottom: "0px" }}>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ marginBottom: "0px" }}>
              <InputLabel id="lblBandeira">Bandeira</InputLabel>
              <Select
                labelId="lblBandeira"
                id="cmbBandeira"
                value={retailer}
                label="Bandeira*"
                onChange={handleChangeSelect}
              >
                {BANDEIRAS.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ marginBottom: "5px", marginLeft: "10px" }}>
            <FormControl fullWidth>
              <TextField
                margin="dense"
                name="id"
                label="Ativo"
                type="text"
                onChange={(e) => setFilter(e.target.value)}
              />
            </FormControl>
          </Grid> 
        </Grid>

        <Grid container spacing={2} sx={{ marginTop: "10px", marginBottom: "5px", marginLeft: "10px" }}>
          {!retailer && (
            <>
              <Typography style={{fontWeight: "bold"}}>Selecione a bandeira</Typography>
            </>
          )}

          {retailer &&
            options.map((option, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{
                  "&.MuiGrid-item": {
                    padding: "0px",
                    margin: "0px",
                  },
                  ".MuiFormControlLabel-root span": {
                    padding: "2px",
                    margin: "0px",
                    fontSize: "13px",
                  },
                  ".MuiFormControlLabel-root:hover": {
                    backgroundColor: "#eee",
                    width: "90%",
                    fontSize: "13px",
                  },
                }}
              >
                <Tooltip title={`Lojas com este ativo: ${option.num_stores}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleChangeCheckbox(option)}
                      value={option.id}
                      checked={option.checked}
                    />
                  }
                  label={`${option.title} (${option.num_stores})`}
                  />
                </Tooltip>
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  );
}

export default AssetCheckboxList;
