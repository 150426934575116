import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Tooltip,
  FormControl
} from "@mui/material";

import { REGIOES, getEstadosPorRegiao } from "../../router/store/types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function StoreCheckboxList({ rows, storesSelected, assetsSelected, callback, edit }) {
  const [filter, setFilter] = useState("");
  const [regiao, setRegiao] = useState(edit ? "Todas" : "SP");
  
  const [regionsFiltro, setRegionsFiltro] = useState([]);

  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setRegiao(value);
  };

  const handleChangeCheckbox = async (option) => {
    const { id, checked } = option;

    let _lojas = selected;

    if (checked) {
      _lojas = selected.filter((el) => el !== id)
    } else {
      _lojas.push(id);
    }

    setSelected(_lojas);

    setOptions((prevOptions) =>
      prevOptions.map((field) =>
        field.id === id ? { ...field, checked: !checked } : field
      )
    );

    await callback(_lojas);
  };

  useEffect(() => {

    console.log('assetsSelected', assetsSelected, storesSelected)

    if (storesSelected)
      setSelected(storesSelected)

    // Filtro inline aplicado ao array de rows
    const filteredRows = rows.filter(
      (row) =>
        (filter === "" ||
          row.id.toLowerCase().includes(filter.toLowerCase()) ||
          row.name.toLowerCase().includes(filter.toLowerCase())) &&
        (regiao === "Todas" ||
          row.region.toUpperCase() == regiao ||
          row.state.toUpperCase() == regiao)
    ).map((el) => ({
      ...el,
      checked: storesSelected.includes(el.id)
    }));

    setOptions(filteredRows)

    const uniqueRegions = [...new Set(rows.map(item => item.region))];
    let regions_filtro = REGIOES.filter((el) => uniqueRegions.includes(el.id))
    setRegionsFiltro(regions_filtro)

  }, [rows, filter, regiao]);

  return (
    <>
      <Box sx={{ padding: 2, height: "calc(100vh - 330px)", overflowY: "scroll", overflowX: "hidden" }}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
          <div style={{width: '300px'}}>
            <FormControl fullWidth>
              <InputLabel id="lblRegiao">Região</InputLabel>
              <Select
                fullWidth
                value={regiao}
                labelId="lblRegiao"
                id="cmbRegiao"
                name="regiao"
                label="Região"
                onChange={handleChangeSelect}
              >
                {edit && <MenuItem key="all" value="Todas">Todas</MenuItem>}
                <MenuItem key="SP" value="SP">São Paulo</MenuItem>

                {regionsFiltro.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.regiao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: '300px' }}>
            <FormControl fullWidth sx={{ marginBottom: "5px" }}>
              <TextField
                margin="dense"
                name="id"
                label="Filtro"
                type="text"
                onChange={(e) => setFilter(e.target.value)}
              />
            </FormControl>
          </div>
          <div style={{ width: '300px' }}>
            <Typography style={{ fontSize: 12, fontWeight: "bold" }}>{options.length === 1 ? `1 loja encontradas` : `${options.length } lojas encontradas`  }</Typography>
          </div>
        </div>

        <Grid container spacing={0} sx={{marginLeft: "20px"}}>

          {options.length == 0 && (
            <>
              <Typography style={{ fontWeight: "bold" }}>Sem lojas para o filtro ou ativos selecionados</Typography>
            </>
          )}

          {options.length > 0 && options.map((option, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                "&.MuiGrid-item": {
                  padding: "0px",
                  margin: "0px",
                },
                ".MuiFormControlLabel-root span": {
                  padding: "2px",
                  margin: "0px",
                  fontSize: "13px",
                },
                ".MuiFormControlLabel-root:hover": {
                  backgroundColor: "#eee",
                  width: "90%",
                  fontSize: "13px",
                },
              }}
            >
              <Tooltip title={`Ativos desta loja: ${option.assets}`} placement="right">
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleChangeCheckbox(option)}
                    value={option.id}
                    checked={option.checked}
                  />
                }
                label={`${option.id} - ${option.name}`}
              />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default StoreCheckboxList;
