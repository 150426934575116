import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  CircularProgress,
  FormHelperText,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DoorBackIcon from '@mui/icons-material/DoorBack';

import { BANDEIRAS, REGIOES, formatDate, getEstadosPorRegiao } from "../../../../router/store/types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StoreCheckboxList from "../../../../components/StoreCheckboxList";
import AssetCheckboxList from "../../../../components/AssetCheckboxList";
import StoreAssetGrid from "../../../../components/StoreAssetGrid";

import { useSelector } from "react-redux";
import ProposalSummary from "../ProposalSummary";

import { useNavigate, useLocation } from "react-router-dom";
import ProposalPdf from "../ProposalPdf";
import e from "cors";

const steps = [
  "Selecione os Ativos",
  "Selecione as Lojas",
  "Verifique os Valores e Quantidades",
  "Preencha os dados do Cliente",
  "Resumo da Proposta",
  "Gerar Documento",
];

function ConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A proposta está revisada?
          <br /><br /><i>Esta versão não está permitindo edição de proposta. <br />Confira com atenção!</i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ProposalForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    client_id: "",
    client: "",
    clientContact: "",
    campaign: "",
    totalCOGS: 0,
    totalMidia: 0,
    inc_user_id: 0,
    inc_date: "",
    comments: "",
    retailer: ""
  });

  const [edit, setEdit] = useState(false);

  const [open, setOpen] = useState(false);

  const [retailer, setRetailer] = useState("");
  const [assets, setAssets] = useState([]);
  const [stores, setStores] = useState([]);
  const [storesAssets, setStoresAssets] = useState([]);
  const [clients, setClients] = useState([]);

  const [assetsSelected, setAssetsSelected] = useState([]);
  const [storesSelected, setStoresSelected] = useState([]);
  const [storesAssetsSelected, setStoresAssetsSelected] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const name = useSelector((state) => state.name);

  const [loading, setLoading] = useState(false);

  // Dialog
  const openConfirm = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {

    await handleSubmit()

    setOpen(false);
  };

  //

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAssetsStep1 = async (_retailer) => {
    setLoading(true);

    // console.log('fetchAssetsStep1: loading... ', _retailer)

    try {
      axios
        .get(`${apiUrl}/api/v1/asset`, {
          params: {
            retailer: _retailer
          }
        })
        .then((response) => {
          //if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setAssets(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setAssets([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const fetchAssetsStep2 = async () => {
    setLoading(true);
    // console.log('fetchAssetsStep2: loading... ', {
    //   retailer: retailer,
    //   assets: assetsSelected
    // })
    try {
      axios
        .get(`${apiUrl}/api/v1/store`, {
          params: {
            retailer: retailer,
            assets: assetsSelected
          }
        })
        .then((response) => {
          //if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setStores(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setStores([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const fetchAssetsStep3 = async () => {
    setLoading(true);
    // console.log('fetchAssetsStep3: loading... ', {
    //   retailer: retailer,
    //   storesAssets: storesAssetsSelected
    // })
    try {
      axios
        .get(`${apiUrl}/api/v1/store-asset`, {
          params: {
            retailer: retailer,
            stores: storesSelected,
            assets: assetsSelected
          }
        })
        .then((response) => {
          //if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setStoresAssets(response.data);
          setStoresAssetsSelected(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setStoresAssets([]);
            setStoresAssetsSelected([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const fetchAssetsStep4 = async () => {
    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/industry`, {})
        .then((response) => {
          //if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setClients(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setClients([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchAssetsStep4();
    };
    fetch();
  }, []);

  const getDataStep1 = async (assets) => {
    setAssetsSelected(assets);
  };

  const getRetailerStep1 = async (retailer) => {
    setRetailer(retailer);
    await fetchAssetsStep1(retailer);
    setValues({ ...values, retailer: retailer });
  };

  const getDataStep2 = (stores) => {
    setStoresSelected(stores);
  };

  const getDataStep3 = (storesAssets) => {
    setStoresAssetsSelected(storesAssets);
  };

  const getDataStep3COGS = (cogs) => {
    setValues({ ...values, totalCOGS: cogs });
  };

  const getDataStep3Midia = (total) => {
    setValues({ ...values, totalMidia: total });
  };

  const getStepContent = (stepIndex, values, handleChange) => {
    switch (stepIndex) {
      case 0:
        return <AssetCheckboxList rows={assets} retailerSelected={retailer} assetsSelected={assetsSelected} callback={getDataStep1} callbackSelect={getRetailerStep1} edit={edit} />;

      case 1:
        return <StoreCheckboxList rows={stores} storesSelected={storesSelected} assetsSelected={assetsSelected} callback={getDataStep2} edit={edit} />;

      case 2:
        return <StoreAssetGrid rows={storesAssets} storesAssetsSelected={storesAssetsSelected} cogs={values.totalCOGS} callback={getDataStep3} callbackCOGS={getDataStep3COGS} callbackMidia={getDataStep3Midia} edit={edit} />;

      case 3:
        return (
          <Box sx={{ marginTop: "10px" }}>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="lblClient">Cliente</InputLabel>
              <Select
                labelId="lblClient"
                id="cmbClient"
                value={values.client_id}
                label="Cliente*"
                onChange={handleChange("client_id")}
                error={!values.client_id}
              >
                {clients.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
              {!values.client_id && (
                <FormHelperText error={!values.client_id}>Cliente é obrigatório</FormHelperText>
              )}
            </FormControl>
            <TextField
              label="Contato do Cliente"
              value={values.clientContact}
              onChange={handleChange("clientContact")}
              fullWidth
              margin="normal"
              error={!values.clientContact}
              helperText={!values.clientContact && "Contato do Cliente é obrigatório"}
            />
            <TextField
              label="Campanha"
              value={values.campaign}
              onChange={handleChange("campaign")}
              fullWidth
              margin="normal"
              error={!values.campaign}
              helperText={!values.campaign && "Campanha é obrigatória"}
            />
            {values.user &&
              <TextField
                label="Sales Manager"
                fullWidth
                margin="normal"
                sx={{ width: "calc(100vh - 230px)" }}
                defaultValue={values.user}
                InputProps={{
                  readOnly: true,
                }}
              />
            }
            {values.inc_date &&
              <TextField
                label="Data da Proposta"
                fullWidth
                margin="normal"
                sx={{ width: "250px", marginLeft: "20px" }}
                defaultValue={values.inc_date}
                InputProps={{
                  readOnly: true,
                }}
              />
            }
            <TextField
              label="Observações"
              variant="outlined"
              color="secondary"
              name={"comments"}
              value={values.comments}
              multiline
              rows={3}
              onChange={handleChange("comments")}
              fullWidth
              margin="normal"
            />
          </Box>
        );
      case 4:
        return (
          <ProposalSummary data={{ proposal: values, assets: storesAssetsSelected }} edit={edit} />
        );

      case 5:
        return (
          <ProposalPdf data={{ proposal: values, assets: storesAssetsSelected }} edit={edit} />
        );

      default:
        return "🛑 Erro! Recarregue a tela.";
    }
  };

  const handleChange = (prop) => (event) => {
    let _values = {}

    if (prop === 'client_id') {
      const _name = clients.find((el) => el.id === event.target.value).name;
      _values = { ...values, client_id: event.target.value, client: _name }
    }
    else {
      _values = { ...values, [prop]: event.target.value }
    }

    setValues(_values);
  };

  const redirectToStart = () => {
    navigate("/proposals");
  };

  const handleNext = async () => {
    // Valida o formulário antes de avançar
    const valid = await validateStep()
    if (valid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateStep = async () => {
    let result = false;
    switch (activeStep) {
      case 0:
        result = assetsSelected.length > 0;
        if (!result)
          toast.error('Ativos não selecionados')
        else {
          if (!edit) setStoresSelected([])
          await fetchAssetsStep2();
        }
        return result;
      case 1:
        result = storesSelected.length > 0
        if (!result) {
          toast.error('Lojas não selecionadas')
        }
        else {
          if (!edit) setStoresAssetsSelected([])
          await fetchAssetsStep3();
        }
        return result;
      case 2:
        result = storesAssetsSelected.length > 0
        if (!result) {
          toast.error('Ativos não encontrados')
        }
        return result;
      case 3:
        result = values.client_id && values.clientContact && values.campaign
        if (!result) {
          toast.error('Dados do Cliente não encontrados')
        }
        return result;
      case 4:
        result = values.proposal_id
        if (!result) {
          toast.error('A Proposta não foi salva ou ocorreu um erro')
        }
        return result;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formattedDate = new Date().toLocaleString('sv-SE', {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(',', '');

      let _values = values;

      _values = { ..._values, inc_user_id: `${name} <${user}>`, inc_date: formattedDate }
      setValues(_values);

      let formData = {
        proposal: _values,
        assets: storesAssetsSelected
      }

      console.log("Formulário enviado:", formData);

      axios
        .post(`${apiUrl}/api/v1/proposal`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 201) {
            values.proposal_id = response.data.proposal_id

            setValues({ ..._values, proposal_id: response.data.proposal_id });

            toast.success('Proposta incluída com sucesso!');
            handleNext()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }

  };

  return (
    <>
      <h2>Nova Proposta</h2>

      <Box sx={{ width: "100%", marginTop: "30px" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepLabel>{label}<br />{activeStep > 0 && i === 0 ? `(${assetsSelected.length})` : activeStep > 1 && i === 1 ? `(${storesSelected.length})` : ""}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ padding: 2 }}>
          {getStepContent(activeStep, values, handleChange)}
          <Box
            sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}
          >
            <Button sx={{ marginRight: 1 }} onClick={redirectToStart} color="error" variant="contained">
              Sair <DoorBackIcon sx={{ marginLeft: "5px" }} />
            </Button>

            <div>
              <Button
                onClick={handleBack}
                sx={{ marginRight: 1 }}
                disabled={activeStep === 0 || activeStep === 5}
                color="secondary"
                variant="contained"
              >
                Voltar
              </Button>

              {activeStep === 4 ? (
                <Button onClick={openConfirm} color="primary" variant="contained">
                  De Acordo <TaskAltIcon sx={{ marginLeft: "5px" }} />
                </Button>
              ) : (
                <Button onClick={handleNext} color="primary" variant="contained" disabled={activeStep === 5}>
                  Próximo <NavigateNextIcon sx={{ marginLeft: "5px" }} />
                </Button>
              )}
            </div>
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}

      </Box>

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
}
