// createApolloClient.js
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const createApolloClient = (retailer, type) => {
  let uri;

  if (Array.isArray(retailer)) retailer = retailer[0];

  if (Array.isArray(type)) type = type[0];

  // const ws = "http://localhost:3001";
  const ws = "https://unlimitail-backlog-api.vercel.app";

  if (retailer === "Carrefour" && type === "FOOD") {
    uri = `${ws}/graphql/carrefour/food`;
  } else if (retailer === "Carrefour" && type === "NFOOD") {
    uri = `${ws}/graphql/carrefour/nfood`;
  } else if (retailer === "Atacadão" && type === "FOOD") {
    uri = "https://your-graphql-endpoint3.com/graphql";
  } else if (retailer === "Atacadão" && type === "NFOOD") {
    uri = "https://your-graphql-endpoint4.com/graphql";
  }

  console.log(uri, retailer, type);

  return new ApolloClient({
    link: new HttpLink({ uri, credentials: "include" }),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
