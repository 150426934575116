import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { useSelector } from "react-redux";

const Home = () => {
  const divStyle = {
    width: "100%",
    height: "600px",
    backgroundImage: 'url("/wallpaper-unlimitail.png")',
    backgroundSize: "contain", // Ajusta a imagem para cobrir toda a área da div
    backgroundPosition: "center", // Centraliza a imagem na div
    backgroundRepeat: "no-repeat", // Evita repetição da imagem
  };

  const user = useSelector((state) => state.user);
  const name = useSelector((state) => state.name);

  return (
    <>
      <div style={divStyle}>
        <h2>Olá, {name}!</h2>

        <br />
        <br />
      </div>
    </>
  );
};

export default Home;
