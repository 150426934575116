// FilterForm.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

import { BANDEIRAS } from "../../../../router/store/types";
import SearchIcon from "@mui/icons-material/Search";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const bandeiras = ["Carrefour", "Atacadão", "Sams Club"];

const FilterForm = ({ onSearch }) => {
  const [bandeira, setBandeira] = useState("Carrefour");
  const [tipo, setTipo] = useState("FOOD");
  const [vtexid, setVtexId] = useState("");
  const [rms, setRms] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(tipo);
    onSearch({ bandeira, tipo, vtexid, rms });
  };

  const handleChange = (event, func) => {
    const {
      target: { value },
    } = event;
    func(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          marginLeft: "0px",
          width: "100%",
          marginBottom: "25px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <FormControl sx={{ marginLeft: "0px" }}>
            <InputLabel id="label-select-labels">Bandeira</InputLabel>
            <Select
              sx={{ width: "300px" }}
              labelId="label-select-labels"
              id="select-labels"
              required
              value={bandeira}
              onChange={(e) => handleChange(e, setBandeira)}
              input={<OutlinedInput label="Bandeira" />}
              MenuProps={MenuProps}
            >
              {bandeiras.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ marginLeft: "0px" }}>
            <InputLabel id="label-select-labels">Tipo</InputLabel>
            <Select
              sx={{ width: "120px" }}
              labelId="label-select-labels"
              id="select-labels"
              required
              value={tipo}
              onChange={(e) => handleChange(e, setTipo)}
              input={<OutlinedInput label="Tipo" />}
              MenuProps={MenuProps}
            >
              <MenuItem key={"FOOD"} value={"FOOD"}>
                FOOD
              </MenuItem>
              <MenuItem key={"NFOOD"} value={"NFOOD"}>
                NFOOD
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ width: "170px" }}
            startIcon={<SearchIcon />}
            type="submit"
          >
            Pesquisar
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <FormControl fullWidth sx={{ marginLeft: "0px" }}>
            <TextField
              label="Vtex ID"
              variant="outlined"
              color="secondary"
              fullWidth
              name={"vtexid"}
              value={vtexid}
              onChange={(e) => handleChange(e, setVtexId)}
            />
          </FormControl>

          <FormControl fullWidth sx={{ marginLeft: "10px" }}>
            <TextField
              label="RMS"
              variant="outlined"
              color="secondary"
              fullWidth
              name={"RMS"}
              value={rms}
              onChange={(e) => handleChange(e, setRms)}
            />
          </FormControl>
        </div>
      </div>
    </form>
  );
};

export default FilterForm;
