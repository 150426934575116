import React, { useState } from "react";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Papa from "papaparse";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Button } from "@mui/material";

import { FormControl } from "@mui/material";

import { FIREBASE_CONFIG } from "../../../../router/store/types";

const app = initializeApp(FIREBASE_CONFIG);

const db = getFirestore();
const auth = getAuth();
const storage = getStorage();

function Loader() {
  const [arquivo, setArquivo] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        setCsvData(results.data);
      },
    });
  };

  const uploadData = async () => {
    if (arquivo === "ativos") {
      loadAssets();
    } else if (arquivo === "ativos-store") {
      loadAssetsByStore();
    } else if (arquivo === "industria") {
      loadIndustry();
    } else if (arquivo === "lojas") {
      loadStores();
    }
  };

  const loadAssetsByStore = async () => {
    setLoading(true);
    try {
      for (const row of csvData) {
        const { retailer, id } = row;

        if (retailer !== "") {
          await setDoc(doc(db, "assets_store", id), row);
          console.log("Documento incluído com ID: ", id);
        }
      }
      alert("Dados carregados com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const loadAssets = async () => {
    setLoading(true);
    try {
      for (const row of csvData) {
        let { retailer, title, type, sell_price } = row;

        if (retailer !== "") {
          row.title = title.toString().trim().toUpperCase();

          await addDoc(collection(db, "assets"), row);
          console.log("Documento incluído com ID: ", title);
        }
      }
      alert("Dados carregados com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const loadIndustry = async () => {
    setLoading(true);
    try {
      const data = await getDocs(collection(db, "industries"));

      for (const row of csvData) {
        const { id, acronym, last_proposal } = row;
        if (id !== "") {
          await setDoc(doc(db, "industries", id.replace("/", "-")), {
            acronym: acronym.replace("/", "-"),
            last_proposal: 0,
          });

          console.log("Documento escrito com ID: ", id);
        }
      }
      alert("Dados carregados com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const loadStores = async () => {
    setLoading(true);
    try {
      for (const row of csvData) {
        const { retailer, id, name, gmv } = row;
        if (retailer !== "") {
          if (name.toString().toLowerCase() === "excluir") {
            await deleteDoc(doc(db, "stores", id));
            console.log("Documento excluído com ID: ", id);
          } else {
            await setDoc(doc(db, "stores", id), {
              retailer: retailer,
              name: name,
              gmv: parseFloat(gmv.replace(",", ".")),
            });
            console.log("Documento incluído com ID: ", id);
          }
        }
      }

      alert("Dados carregados com sucesso!");
    } catch (error) {
      console.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "20px", width: "300px" }}>
      <FormControl fullWidth style={{ marginTop: "10px", width: "300px" }}>
        <InputLabel id="demo-multiple-name-label">Arquivo *</InputLabel>
        <Select
          required
          value={arquivo}
          name="retailer"
          onChange={(e) => setArquivo(e.target.value)}
          input={<OutlinedInput label="Arquivo" />}
        >
          <MenuItem key={"lojas"} value={"lojas"}>
            Lojas
          </MenuItem>
          <MenuItem key={"industria"} value={"industria"}>
            Indústria
          </MenuItem>
          <MenuItem key={"ativos"} value={"ativos"}>
            Cadastro Ativos
          </MenuItem>
          <MenuItem key={"ativos-store"} value={"ativos-store"}>
            Ativos Por Loja
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px", width: "300px" }}>
        <input
          style={{ fontSize: "14px", width: "300px" }}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px", width: "300px" }}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          size="large"
          style={{ width: "150px", marginRight: "10px" }}
          onClick={uploadData}
          disabled={loading}
        >
          {loading ? "Carregando..." : "Upload"}
        </Button>
      </FormControl>
    </div>
  );
}

export default Loader;
