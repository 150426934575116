import React, { useState, useEffect } from "react";
import { Box, TextField, FormControl, Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

import { LOGIN_USER } from "../../../router/store/types";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";

import {
  getAuth,
  signInWithCustomToken,
  signInAnonymously,
} from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";

const Login = ({ onLogin, firebaseApp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const [loading, setLoading] = useState(true);

  // ****************************
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);

  const auth = getAuth(firebaseApp);

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  const signInWithFirebase = async (customToken) => {
    signInAnonymously(auth)
      .then((userCredential) => {
        // console.log("Usuário autenticado no Firebase:", userCredential.user);
        console.log("Usuário autenticado...");
      })
      .catch((error) => {
        console.error("Erro na autenticação do Firebase:", error.toString());
      });
  };

  // ****************************

  const loginSuccess = (name, email, _accessToken) => {
    toast.success("Login efetuado com sucesso!");

    onLogin();

    signInWithFirebase(_accessToken);

    dispatch({ type: LOGIN_USER, user: email, name: name });

    localStorage.setItem("user", email);

    history("/home");
  };

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((response) => {
          // console.log("resp", response);
          setAccessToken(response.accessToken);
          loginSuccess(
            response.account.name,
            response.account.username,
            response.accessToken
          );
        })
        .catch((e) => {
          instance
            .acquireTokenPopup(request)
            .then((response) => {
              setAccessToken(response.accessToken);
            })
            .catch((e) => {
              console.error(e);
            });
        });
    }
  }, [isAuthenticated, accounts, instance]);

  useEffect(() => {
    const savedEmail = localStorage.getItem("lastEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const db = getFirestore(firebaseApp);

  const getUser = async () => {
    try {
      const docRef = doc(db, "users", email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return { ...data, id: email };
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error getting document: ", error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setEmailError(false);
    setPasswordError(false);

    localStorage.setItem("lastEmail", email);

    if (email === "") {
      setEmailError(true);
      toast.error("Verifique as credenciais.");
      return;
    }
    if (password === "") {
      setPasswordError(true);
      toast.error("Verifique as credenciais.");
      return;
    }

    if (email && password) {
      const data = await getUser();

      if (data == null) {
        setPasswordError(true);
        toast.error("Verifique as credenciais.");
        return;
      } else if (data !== null) {
        const hash_password = CryptoJS.SHA1(password).toString();
        if (data.password === hash_password) {
          loginSuccess(data.name, data.id);
        } else {
          setPasswordError(true);
          toast.error("Verifique a senha.");
          return;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          minHeight="100vh"
        >
          <FormControl
            sx={{ marginLeft: "0px", marginBottom: "25px", minWidth: 400 }}
          >
            <h2>Log In</h2>
            <TextField
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              color="secondary"
              type="email"
              sx={{ mb: 3 }}
              fullWidth
              value={email}
              error={emailError}
              autoComplete={"off"}
            />
            <TextField
              label="Senha"
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              color="secondary"
              type="password"
              value={password}
              error={passwordError}
              fullWidth
              sx={{ mb: 3 }}
              autoComplete={"new-password"}
            />

            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                style={{ width: "150px", marginRight: "10px" }}
                startIcon={<LoginIcon />}
              >
                Sign in
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "250px" }}
                startIcon={<MicrosoftIcon />}
                onClick={handleLogin}
              >
                Log in Unlimitail Net
              </Button>
            </div>
          </FormControl>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default Login;
